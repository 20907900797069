// Angular modules
import { Component, ElementRef, EventEmitter, HostBinding, Inject, Input, LOCALE_ID, Output, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';

// Models
import { DataList } from '@models/index';

// Services
import { StoreService } from '@services/store.service';

@Component({
  selector: 'lockbin-header-tools',
  templateUrl: './header-tools.component.html',
  styleUrls: ['./header-tools.component.scss'],
})
export class HeaderToolsComponent<T> implements OnInit {
  @HostBinding('class') hostClasses = 'header-tools';

  @Input() bulkOperations: Array<any> = [];
  @Input() numElementsChecked: number = 0;
  //TODO: Esto hay que cambiarlo por lo de abajo. Se hace así por compatibilidad con el listado de usuarios
  // @Input() bulkOperations: Array<DataListOperation> = [];

  @Input() useSearch: boolean = true;
  @Input() searchPlaceholder: string = 'Find........';

  //TODO: Hay que cambiar el tipo Any por DataListHeader. Se mantiene por compartibilidad del ejemplo de usuarios
  @Input() dataListHeader!: any;

  @Input() dataListWrapper!: DataList<T>;

  @Output() doClick: EventEmitter<boolean> = new EventEmitter();
  @Output() doBulkOperation: EventEmitter<string> = new EventEmitter<string>();
  @Output() changePaginationItemsPerPage: EventEmitter<number> = new EventEmitter<number>();
  @Output() changePaginationSortOrder: EventEmitter<string> = new EventEmitter<string>();
  @Output() doSearch: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('headerSearchText') headerSearchText?: ElementRef<HTMLInputElement>;

  public paginationItemsPerPage: Array<number> = [10, 20, 50];
  public paginationItemsPerPageSelected = 10;
  public paginationSortOrder= "DESC";

  public locale;
  public isSearchOpen = false;
  public isSearchApplied = false;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    public storeService: StoreService) {
    this.locale = locale;
  }

  public ngOnInit(): void {
    this.paginationItemsPerPage = this.dataListHeader.pagination.itemsPerPageRange;
    this.paginationItemsPerPageSelected = this.dataListHeader.pagination.itemsPerPage;
  }

  public onClickBulkOperation(operation: string) {
    this.doBulkOperation.emit(operation);
  }

  public openSearch(): void {
    this.isSearchOpen = true;
    this.headerSearchText?.nativeElement.focus();
  }

  public closeSearch(): void {
    this.isSearchOpen = false;
  }

  public clickOutsideSearch(target: any): void {
    // Comprobamos que aunque haya hecho clic fuera, no haya clicado en el botón de apertura.
    const toogleSearchButton = target.closest('.toggle-search');
    if (!toogleSearchButton) {
      this.isSearchOpen = false;
    }
  }

  doChangePaginationItemsPerPage(event: Event, numItems: number) {
    event.preventDefault();
    this.paginationItemsPerPageSelected = numItems;
    this.changePaginationItemsPerPage.emit(numItems);
  }

  doChangePaginationSortOrder(event: Event, sortOrder: string) {
    event.preventDefault();
    this.paginationSortOrder = sortOrder;
    this.changePaginationSortOrder.emit(sortOrder);
  }

  onDoSearch() {
    this.doSearch.emit(this.headerSearchText?.nativeElement.value);
  }
}
