import { Injectable } from '@angular/core';
import { IMenuItem } from '@security/models/menu-item.interface';

@Injectable({
  providedIn: 'root',
})
export class MenuService {

  actualItems!: IMenuItem[];

  menuItemsRoot: IMenuItem[] = [
    // {
    //   "title": "Administración",
    //   "isHeading": true,
    //   "route": "/administracion",
    //   "cssClasses": "AAAAAAAAA",
    // },
    {
      title: 'Mapa de estado',
      isHeading: false,
      // "icon": "map",
      iconSVG: 'assets/img/menu/map.svg',
      route: '/status-map',
      cssClasses: '',
      roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER', 'ROLE_SUBCONTRACT'],
      childs: [],
    },
    {
      title: 'Estadísticas',
      isHeading: false,
      // "icon": "map",
      iconSVG: 'assets/img/menu/stats.svg',
      route: '/estadisticas',
      cssClasses: '',
      roles: [],
      childs: [],
    },
    // {
    //   "title": "Configuraciones de Interfaz",
    //   "isHeading": false,
    //   "icon": "globe",
    //   "route": "/configuraciones-interfaz",
    //   "cssClasses": "",
    // },
    // {
    //   "title": "Equipos",
    //   "isHeading": true,
    //   "route": "/equipos",
    //   "cssClasses": "",
    // },
    // {
    //   "title": "Mapa",
    //   "isHeading": false,
    //   "icon": "map",
    //   "route": "/equipos/gestion-equipos/map",
    //   "cssClasses": "",
    // },

    // {
    //   "title": "Dispositivos",
    //   "isHeading": true,
    //   "route": "/equipos",
    //   "cssClasses": "",
    // },
    {
      title: '',
      isSeparator: true,
      route: '',
      childs: [],
    },
    {
      title: 'Registro de historiales',
      isHeading: false,
      iconSVG: 'assets/img/menu/folder.svg',
      route: '/ciudadanos',
      cssClasses: '',
      disabled: true,
      roles: [],
      // roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
      childs: [
        {
          title: 'Incidencias',
          isHeading: false,
          route: '/alertas/visor-alertas',
          cssClasses: '',
          roles: [],
          // roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
          disabled: true,
          childs: [],
        },
      ],
    },
    {
      title: 'Grupos de equipos',
      isHeading: false,
      iconSVG: 'assets/img/menu/groups.svg',
      route: '/',
      cssClasses: '',
      roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
      disabled: true,
      childs: [
        {
          title: 'CP',
          isHeading: false,
          route: '/administracion/configuracion/grupos-equipos/codigos-postales/list',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        // {
        //   title: 'Zonas',
        //   isHeading: false,
        //   route: '/administracion/configuracion/grupos-equipos/zonas/list',
        //   cssClasses: '',
        //   disabled: false,
        //   childs: [],
        //   roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        // },
        {
          title: 'Rutas',
          isHeading: false,
          route: '/administracion/configuracion/grupos-equipos/rutas/list',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        {
          title: 'Horarios',
          isHeading: false,
          route: '/administracion/configuracion/grupos-equipos/horarios/list',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        {
          title: 'Personalizados',
          isHeading: false,
          route: '/administracion/configuracion/grupos-equipos/personalizados/list',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
      ],
    },
    {
      title: '',
      isSeparator: true,
      route: '',
      childs: [],
    },
    {
      title: 'Puesta en marcha equipos',
      isHeading: false,
      iconSVG: 'assets/img/menu/equipment-enable.svg',
      route: '/',
      cssClasses: '',
      roles: ['ROLE_ROOT', 'ROLE_MANAGER','ROLE_MAINTENANCE'],
      childs: [
        {
          title: 'Recepción de lotes',
          isHeading: false,
          route: '/equipos/gestion-dispositivos/lot-import',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
        {
          title: 'Creación de contenedores y plantillas',
          isHeading: false,
          route: '/equipos/plantillas-contenedores/list',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
        {
          title: 'Validación y Vinculación de Equipos',
          isHeading: false,
          route: '/equipos/gestion-dispositivos/validate',
          disabled: false,
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER', 'ROLE_SUBCONTRACT', 'ROLE_MAINTENANCE'],
        },
        {
          title: 'Gestión de equipos',
          isHeading: false,
          route: '/equipos/gestion-equipos/list',
          cssClasses: '',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        {
          title: 'Gestión de dispositivos',
          isHeading: false,
          icon: 'cpu',
          route: '/equipos/gestion-dispositivos',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
      ],
    },
    {
      title: '',
      isSeparator: true,
      route: '',
      childs: [],
    },
    {
      id: 'tarjetas',
      title: 'Ciudadanos',
      isHeading: false,
      iconSVG: 'assets/img/menu/citizen.svg',
      route: '/',
      cssClasses: '',
      roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
      childs: [
        {
          title: 'Gestión de tarjetas',
          isHeading: false,
          icon: 'cc',
          route: '/tarjetas/gestion-tarjetas/list',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        {
          title: 'Importación tarjetas',
          isHeading: false,
          icon: 'cc',
          route: '/tarjetas/gestion-tarjetas/lot-import',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        {
          title: 'Grupos de tarjetas',
          isHeading: false,
          icon: 'cards',
          route: '/tarjetas/grupos-tarjetas',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
        {
          title: 'Rangos de tarjetas',
          isHeading: false,
          icon: 'user-list',
          route: '/tarjetas/rangos-tarjetas',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER', 'ROLE_CUSTOMER'],
        },
      ],
    },
    {
      title: 'Usuarios y permisos',
      isHeading: false,
      iconSVG: 'assets/img/menu/security.svg',
      route: '/administracion/seguridad',
      cssClasses: 'BBBBBBBBBBB',
      roles: ['ROLE_ROOT'],
      childs: [
        {
          title: 'Usuarios',
          route: '/usuarios-list',
          cssClasses: 'CCCCCCCC',
          disabled: false,
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Roles',
          route: '/roles-list',
          disabled: false,
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
      ],
    },
    {
      title: '',
      isSeparator: true,
      route: '',
      childs: [],
    },
    {
      title: 'Configuración',
      isHeading: false,
      iconSVG: 'assets/img/menu/settings.svg',
      route: '/administracion/configuracion',
      cssClasses: '',
      roles: ['ROLE_ROOT'],
      childs: [
        {
          title: 'Tipos de equipos',
          route: '/tipos-equipos',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Modelos de equipos',
          route: '/modelos-equipos',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Categorías de equipos',
          route: '/categorias-equipos',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        // {
        //   title: 'Grupos de equipos',
        //   route: '/grupos-equipos',
        //   cssClasses: '',
        //   childs: [],
        //   roles: ['ROLE_ROOT'],
        // },
        {
          title: 'Estados de equipos',
          route: '/estados',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        // {
        //   title: 'Gestión de dispositivos ??',
        //   isHeading: false,
        //   icon: 'cpu',
        //   route: '/equipos/gestion-dispositivos',
        //   cssClasses: '',
        //   childs: [],
        //   roles: ['ROLE_ROOT'],
        // },
        // {
        //   title: 'Gestión de equipos ??',
        //   isHeading: false,
        //   icon: 'trash-alt',
        //   route: '/equipos/gestion-equipos',
        //   cssClasses: '',
        //   childs: [],
        //   roles: ['ROLE_ROOT'],
        // },
        {
          title: 'Tipos de Operaciones',
          route: '/tipos-operaciones',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Tipos de Alertas',
          route: '/tipos-alertas',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Tipos de Parámetros',
          route: '/tipos-parametros',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Tipos de Sensor',
          route: '/tipos-sensores',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Tipos de FW',
          route: '/tipos-firmware',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Tipos de HW',
          route: '/tipos-hardware',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Países',
          isHeading: false,
          route: '/paises',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Regiones',
          isHeading: false,
          route: '/regiones',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
        {
          title: 'Ciudades',
          isHeading: false,
          route: '/ciudades',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
      ],
    },
    {
      title: 'Comunicaciones',
      isHeading: false,
      icon: 'signal',
      route: '/comunicaciones-mqtt',
      cssClasses: '',
      roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
      childs: [
        {
          title: 'Broker MQTT',
          isHeading: false,
          // "icon": "wallet-out",
          route: '/brokers-mqtt',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
        {
          title: 'Recepciones MQTT',
          isHeading: false,
          // "icon": "wallet-in",
          route: '/recepciones-mqtt',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
        {
          title: 'Envíos MQTT',
          isHeading: false,
          // "icon": "wallet-out",
          route: '/envios-mqtt',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
      ],
    },
    {
      title: 'Clientes',
      isHeading: false,
      icon: 'user-list',
      route: '/clientes',
      cssClasses: '',
      roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
      childs: [
        {
          title: 'Gestión de clientes',
          isHeading: false,
          icon: 'user-list',
          route: '/gestion-clientes',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT', 'ROLE_MANAGER'],
        },
        {
          title: 'Credenciales de clientes',
          isHeading: false,
          icon: 'lock-alt',
          route: '/credenciales-clientes',
          cssClasses: '',
          childs: [],
          roles: ['ROLE_ROOT'],
        },
      ],
    },
  ];

  menuItemsCustomer: IMenuItem[] = [
    {
      title: 'Administración',
      isHeading: true,
      route: '/administracion',
      cssClasses: 'AAAAAAAAA',
      childs: [],
    },
    {
      title: 'Seguridad',
      isHeading: false,
      icon: 'users',
      route: '/administracion/seguridad',
      cssClasses: 'BBBBBBBBBBB',
      childs: [
        {
          title: 'Usuarios',
          route: '/usuarios-list',
          cssClasses: 'CCCCCCCC',
          childs: [],
        },
        {
          title: 'Roles',
          route: '/roles-list',
          cssClasses: 'CCCCCCCC',
          childs: [],
        },
      ],
    },
    {
      title: 'Configuración',
      isHeading: false,
      icon: 'setting',
      route: '/administracion/configuracion',
      cssClasses: '',
      childs: [
        {
          title: 'Tipos de Operaciones',
          route: '/tipos-operaciones',
          cssClasses: '',
          childs: [],
        },
        {
          title: 'Tipos de Alertas',
          route: '/tipos-alertas',
          cssClasses: '',
          childs: [],
        },
        {
          title: 'Tipos de Parámetros',
          route: '/tipos-parametros',
          cssClasses: '',
          childs: [],
        },
        {
          title: 'Tipos de Sensor',
          route: '/tipos-sensores',
          cssClasses: '',
          childs: [],
        },
        {
          title: 'Tipos de FW',
          route: '/tipos-firmware',
          cssClasses: '',
          childs: [],
        },
        {
          title: 'Tipos de HW',
          route: '/tipos-hardware',
          cssClasses: '',
          childs: [],
        },
      ],
    },
    {
      title: 'Tarjetas',
      isHeading: true,
      route: '/tarjetas',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Gestión de tarjetas',
      isHeading: false,
      icon: 'cc',
      route: '/tarjetas/gestion-tarjetas',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Grupos Tarjetas',
      isHeading: false,
      icon: 'cards',
      route: '/tarjetas/grupos-tarjetas',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Rangos Tarjetas',
      isHeading: false,
      icon: 'user-list',
      route: '/tarjetas/rangos-tarjetas',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Dispositivos',
      isHeading: true,
      route: '/equipos',
      cssClasses: '',
      childs: [],
    },

    {
      title: 'Gestión de dispositivos',
      isHeading: false,
      icon: 'cpu',
      route: '/equipos/gestion-dispositivos',
      cssClasses: '',
      childs: [],
    },

    {
      title: 'Alertas',
      isHeading: true,
      route: '/alertas',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Visor de alertas',
      isHeading: false,
      icon: 'alert',
      route: '/alertas/visor-alertas',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Comunicaciones MQTT',
      isHeading: true,
      route: '/comunicaciones-mqtt',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Broker MQTT',
      isHeading: false,
      icon: 'wallet-out',
      route: '/comunicaciones-mqtt/brokers-mqtt',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Recepciones MQTT',
      isHeading: false,
      icon: 'wallet-in',
      route: '/comunicaciones-mqtt/recepciones-mqtt',
      cssClasses: '',
      childs: [],
    },
    {
      title: 'Envíos MQTT',
      isHeading: false,
      icon: 'wallet-out',
      route: '/comunicaciones-mqtt/envios-mqtt',
      cssClasses: '',
      childs: [],
    },
  ];

  constructor() {}

  getMenuByRole(role: string): IMenuItem[] {

    if (role.length > 0) {

      this.actualItems = this.filterMenuByRole(this.menuItemsRoot, role);

      return this.actualItems;
    }

    // if (role === 'ROLE_CUSTOMER') return this.menuItemsCustomer;
    // if (role === 'ROLE_MANAGER') return this.menuItemsRoot;
    // if (role === 'ROLE_ROOT') return this.menuItemsRoot;

    // 'ROLE_ROOT'
    // 'ROLE_MANAGER'
    // 'ROLE_CUSTOMER'
    // 'ROLE_SUBCONTRACT'
    // 'ROLE_MAINTENANCE'

    // role: [],

    return [];
  }

  private filterMenuByRole(menuItems: IMenuItem[], role: string): IMenuItem[] {
    return menuItems
      .filter((item: IMenuItem) => (item.roles ? item.roles.includes(role) : true))
      .map((item: IMenuItem) => {
        return {
          ...item,
          childs: this.filterMenuByRole(item.childs || [], role),
        };
      });
  }
}
