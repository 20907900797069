
export enum DEVICE_INSTALLATION_STATE_TYPE {

  PENDING_INSTALL = 0,
  INSTALLED = 1

}

export function getDeviceInstallationStateTypes(): { value: number, name: string }[] {

  return Object.keys(DEVICE_INSTALLATION_STATE_TYPE)
  .filter((key: any) => !isNaN(Number(DEVICE_INSTALLATION_STATE_TYPE[key])))
  .map((key: any) => {
    return { value: Number(DEVICE_INSTALLATION_STATE_TYPE[key]), name: key };
  });
}
